<template>
  <div>
    <!--      Website Intro       -->
    <div
      class="intro container d-flex align-items-sm-center align-items-md-start"
    >
      <div class="intro-text w-100">
        <h1 class="">اطلب من التشليح بدون وسطاء!</h1>
        <p
          class="secound-intro-text text-break"
          style="font-size: 1.5rem; margin-top: 1rem; font-weight: normal"
        >
          أرخص طريقة لشراء قطع السيارات المستعملة على الإنترنت
        </p>
      </div>
    </div>

    <!--        Part Order Section      -->
    <div
      class="order-parts-section-home container-fluid"
      style="background-color: rgba(140, 140, 140, 0.271)"
    >
      <!-- The progressbar for part order -->

      <div class="row d-flex justify-content-center" v-if="windowWidth > 1000">
        <div class="col-7">
          <ul class="progressbar">
            <li class="active" >دخل بيانات سيارتك</li>
            <li
              :class="{ active: stepN == 1 || stepN == 2 }"
              
            >
              دخل بيانات القطعة
            </li>
            <li :class="{ active: stepN == 2 }" >سجل معنا</li>

            <li>استعرض العروض وكّمل طلبك</li>
          </ul>
        </div>
      </div>

      <!-- Part order form and carousel for oredring steps -->

      <div class="row d-flex justify-content-around">
        <!-- Part order form  -->

        <div
          class="
            order-part-form-col
            col-lg-5 col-sm-12
            d-flex
            justify-content-center
            py-4
            px-md-5
          "
        >
          <app-part-order />
        </div>

        <!--  oreder steps carousel  -->
        <div class="col-lg-5 col-sm-12 d-flex justify-content-center">
          <div class="bd-example w-100">
            <div
              id="carousel-home"
              class="carousel slide row d-flex justify-content-center"
              data-ride="carousel"
              style="height: 30rem"
            >
              <div class="carousel-inner w-100 h-100">
                <div class="carousel-item active carousel-home-images">
                  <img
                    rel="preload"
                    src="../img/Forms-pana.svg"
                    alt="home order section"
                    style="width: 15rem; height: 15rem"
                  />

                  <div class="carousel-caption" style="padding-bottom: 50px">
                    <h3 style="color: rgba(9, 88, 137, 1); font-weight: normal">
                      عبي بيانات القطعة
                    </h3>
                  </div>
                </div>
                <div class="carousel-item carousel-home-images">
                  <img
                    rel="preload"
                    src="../img/Online wishes list-pana.svg"
                    alt="Get prices"
                    style="width: 15rem; height: 15rem"
                  />

                  <div class="carousel-caption" style="padding-bottom: 50px">
                    <h3 style="color: rgba(9, 88, 137, 1); font-weight: normal">
                      استقبل عروض الأسعار مرتبة بالأرخص
                    </h3>
                  </div>
                </div>
                <div class="carousel-item carousel-home-images">
                  <img
                    rel="preload"
                    src="../img/home-deliver-section.svg"
                    alt="Accpet & Ship"
                    style="width: 15rem; height: 15rem"
                  />

                  <div class="carousel-caption" style="padding-bottom: 50px">
                    <h3 style="color: rgba(9, 88, 137, 1); font-weight: normal">
                      اقبل العرض الأفضل،ادفع ونشحن لك
                    </h3>
                  </div>
                </div>
              </div>

              <div class="row">
                <ol class="carousel-indicators" style="bottom: 0rem !important">
                  <li
                    data-target="#carousel-home"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li data-target="#carousel-home" data-slide-to="1"></li>
                  <li data-target="#carousel-home" data-slide-to="2"></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PartOrder from "../components/PartOrder/PartOrder.vue";

export default {
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  components: {
    appPartOrder: PartOrder,
  },
  computed: {
    stepN: {
      get: function() {
        return this.$store.state.glopal.stepNo;
      },
      set: function(newValue) {
        this.$store.state.glopal.stepNo = newValue;
      },
    },
  },
};
</script>

<style>
.intro {
  height: auto;
  background-image: url(../img/g10.svg);
  background-color: #fff;
  background-position: auto;
  background-repeat: no-repeat;
  background-size: auto 100%;

  justify-content: center !important;
  position: static;
  padding-top: 2rem;
}

/* if screen is small than 1033px style */
@media only screen and (max-width: 1033px) {
  .navbarSupportedContent a {
    font-size: 15px;
  }

  .navbarSupportedContent li {
    margin-left: 0;
    margin-right: 2rem;
  }

  .intro-text {
    width: 20rem;
    text-align: right;
  }

  .progressbar li {
    font-size: 0.7rem;
    font-weight: bold;
  }

  .progressbar li:before {
    width: 18px;
    height: 18px;
    line-height: 20px;
    font-size: 0.5rem;
  }

  .progressbar li.active:before {
    border: none;
  }

  .progressbar li:after {
    height: 0.2rem;
    top: 0.5rem;
  }
}

/* if screen is small than 780px style */
@media only screen and (max-width: 780px) {
  .intro {
    background-position: left center;
  }

  .intro-text p {
    font-size: 1.3rem !important;
    width: 20rem;
    text-align: right;
  }

  .intro-text p.secound-intro-text {
    font-size: 1rem !important;
  }

  .container.intro {
    max-width: 100% !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .progressbar li {
    font-size: 0.7rem;
    font-weight: bold;
  }

  .progressbar li:before {
    width: 18px;
    height: 18px;
    line-height: 20px;
    font-size: 0.7rem;
  }

  .progressbar li.active:before {
    border: none;
  }

  .progressbar li:after {
    height: 0.2rem;
    top: 0.5rem;
  }

  .order-part-form {
    margin-right: 0;
    width: 100%;
  }

  #carousel-home {
    width: 100% !important;
  }
}

/* if screen is small than 600px style */
@media only screen and (max-width: 600px) {
  .intro {
    background-image: url(../img/g102.svg);
    height: 19rem;
    background-size: cover;
    background-position: bottom center;
  }

  .intro-text {
    margin-top: -25px;

    text-align: center;
    width: auto !important;
    height: inherit;
  }

  .intro-text h1 {
    font-size: 1rem !important;
  }

  .intro-text p {
    font-size: 1rem !important;
    text-align: center;
  }

  .progressbar li {
    font-size: 0.3rem;
    font-weight: bold;
  }

  .progressbar li:before {
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 0.3rem;
  }

  .progressbar li.active:before {
    border: none;
  }

  .progressbar li:after {
    height: 0.1rem;
    top: 0.5rem;
  }

  .carsouel-caption h3 {
    font-weight: normal;
  }

  .footer {
    justify-content: center !important;
  }

  .col-md-auto {
    width: auto;
  }
}
</style>
